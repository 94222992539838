<template>
  <div class="container-scroller">
    <div class="auth container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth">
        <div class="row flex-grow">
          <div class="col-lg-4 col-md-6 mx-auto">
            <div class="auth-form-light text-center p-5">
              <div class="brand-logo">
                <img src="/assets/images/brand.png">
              </div>
              <form class="pt-3">
                <div class="form-group">
                  <input v-model="first_name" type="text" class="form-control form-control-lg" placeholder="Nombre">
                  <div v-if="errors.hasOwnProperty('first_name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.first_name[0]}}</small>
                  </div>
                </div>
                <div class="form-group">
                  <input v-model="last_name" type="text" class="form-control form-control-lg" placeholder="Apellido">
                  <div v-if="errors.hasOwnProperty('last_name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.last_name[0]}}</small>
                  </div>
                </div>
                <div class="form-group">
                  <input v-model="email" type="email" class="form-control form-control-lg" placeholder="Correo eletrónico">
                  <div v-if="errors.hasOwnProperty('email')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.email[0]}}</small>
                  </div>
                </div>
                <div class="form-group">
                  <input v-model="password" type="password" class="form-control form-control-lg" placeholder="Contraseña">
                  <div v-if="errors.hasOwnProperty('password')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.password[0]}}</small>
                  </div>
                </div>
                <div class="form-group">
                  <input v-model="password_confirmation" type="password" class="form-control form-control-lg" placeholder="Confirmar Contraseña">
                  <div v-if="errors.hasOwnProperty('password_confirmation')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.password_confirmation[0]}}</small>
                  </div>
                </div>
                <div class="mt-3">
                  <a @click.prevent="registerUser" class="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn" href="#">REGISTRAR</a>
                </div>
                <div class="my-2 text-right mt-3">
                  <router-link :to="{ name: 'login' }" class="auth-link text-black">Acceder</router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Login',
  data: () => {
    return {
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      password_confirmation: null,
      errors: []
    }
  },
  methods: {
    ...mapActions('Authentication', ['createToken']),
    registerUser () {
      this.createToken({
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation
      })
        .then(() => {
          if (this.loggedIn) {
            this.$router.push({ name: 'home' })
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    }
  },
  computed: {
    ...mapGetters('Authentication', ['loggedIn'])
  }
}
</script>
